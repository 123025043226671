/**
 * Foundation for Sites
 * Version 6.7.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
#case-list-container:only-of-type {
  margin-bottom: 30px;
  margin-top: 120px; }
  @media print, screen and (min-width: 64em) {
    #case-list-container:only-of-type {
      margin-top: 150px;
      margin-bottom: 60px; } }

#case-list {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px; }
  @media print, screen and (min-width: 64em) {
    #case-list {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 60px; } }
  @media screen and (min-width: 90em) {
    #case-list {
      grid-template-columns: repeat(4, minmax(0, 1fr)); } }
  @media print, screen and (max-width: 74.99875em) {
    #case-list {
      padding: 0 15px; } }
  #case-list > div {
    position: relative;
    display: inline-flex;
    align-items: flex-end; }
    #case-list > div > a {
      display: block;
      width: 100%; }
  #case-list picture, #case-list video {
    position: relative; }
  #case-list picture {
    margin-bottom: calc(0.5rem + 7px); }
    #case-list picture img {
      width: 100%; }
  #case-list video {
    margin-bottom: 0.5rem; }
  #case-list .span-2 picture, #case-list .span-2 video {
    aspect-ratio: 4 / 3; }
  #case-list picture img,
  #case-list video {
    transition: all .25s ease-in-out; }
  #case-list .case-data {
    color: #000000;
    min-height: 130px;
    font-size: 0.6875rem;
    transition: all .25s ease-in-out; }
    #case-list .case-data span {
      display: block; }
    #case-list .case-data .listing-desc, #case-list .case-data .listing-link {
      color: #77777B; }
    @media print, screen and (min-width: 64em) {
      #case-list .case-data .listing-title {
        font-size: 1.125rem; }
      #case-list .case-data .listing-desc, #case-list .case-data .listing-link {
        font-size: 0.875rem; } }
    #case-list .case-data .listing-link {
      display: inline-block;
      position: relative; }
      #case-list .case-data .listing-link:after {
        transition: all .25s ease-in-out;
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #77777B;
        bottom: 4px; }
  #case-list .categories {
    font-size: 0.625rem; }
    @media print, screen and (min-width: 64em) {
      #case-list .categories {
        font-size: 0.875rem; } }
    #case-list .categories, #case-list .categories [data-filter] {
      color: #77777B; }
    #case-list .categories [data-filter] {
      display: inline-block; }
      #case-list .categories [data-filter]:hover {
        cursor: pointer;
        color: #77777B; }
  #case-list a:hover picture img,
  #case-list a:hover video {
    will-change: transform;
    transform: scale(1.01); }
  #case-list a:hover .case-data,
  #case-list a:hover + .case-data {
    padding-left: 5px; }
  #case-list a:hover .listing-link {
    color: #000000; }
    #case-list a:hover .listing-link:after {
      background-color: #000000; }
  #case-list .case.no-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media print, screen and (max-width: 63.99875em) {
      #case-list .case.no-media {
        grid-column: span 2; } }
  #case-list .case.span-2 {
    grid-column: span 2; }

#case-list .case.hide {
  display: none; }

#sorting-info {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 20px;
  right: 14px;
  cursor: pointer; }
  @media screen and (min-width: 75em) {
    #sorting-info {
      display: flex; } }
  #sorting-info:before {
    content: '';
    display: inline-block;
    width: 25px;
    height: 25px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.029 29.862'%3E%3Cg data-name='Group 9743' fill='%2362ff00'%3E%3Cpath data-name='Path 9470' d='M.109 2.483 0 6.043l10.645-.009h8.74l10.536-.006.109-3.56Z'/%3E%3Cpath data-name='Path 9471' d='M.109 13.178 0 16.739l10.645-.01h8.74l10.536-.006.109-3.56Z'/%3E%3Cpath data-name='Path 9472' d='M.109 23.834 0 27.395l10.645-.011h19.276l.109-3.56Z'/%3E%3Cpath data-name='Rectangle 2509' d='M5.566 0h8.509v8.51H5.566z'/%3E%3Cpath data-name='Rectangle 2510' d='M18.329 10.696h8.509v8.509h-8.509z'/%3E%3Cpath data-name='Rectangle 2511' d='M9.82 21.353h8.509v8.509H9.82z'/%3E%3C/g%3E%3C/svg%3E");
    margin-bottom: 20px; }
  #sorting-info svg {
    transition: all .25s ease-in-out 500ms; }
  #sorting-info svg#sort-text {
    height: 328px;
    width: 29px; }
  #sorting-info svg#sort-text text {
    display: none;
    fill: #000000;
    font-size: 1.125rem;
    font-family: #000000;
    font-weight: 600; }
    @media print, screen and (min-width: 40em) {
      #sorting-info svg#sort-text text {
        display: block; } }

#case-sorting.open svg {
  transition: all .25s ease-in-out;
  opacity: 0; }

#case-sorting.open #sorting-info:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.908 23.908'%3E%3Cpath data-name='Path 9456' d='M18.232 23.908 0 5.676 5.676 0l18.232 18.189Zm-12.516-.172L.172 18.103 18.016.258l5.59 5.547Z' fill='%2362FF00'/%3E%3C/svg%3E"); }

.case .paragraph-container a {
  font-size: 1.125rem;
  position: relative;
  color: #77777B; }
  .case .paragraph-container a:after {
    transition: all .25s ease-in-out;
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #77777B;
    bottom: 0px; }
  .case .paragraph-container a:hover {
    color: #000000; }
    .case .paragraph-container a:hover:after {
      background-color: #000000; }

#case-sorting {
  color: #000000;
  z-index: 1;
  position: fixed;
  top: 290px;
  right: 0;
  transition: all .25s ease-in-out;
  display: none;
  bottom: 0; }
  @media print, screen and (min-width: 64em) {
    #case-sorting {
      display: block;
      bottom: auto; } }
  #case-sorting p,
  #case-sorting p a, #case-sorting li,
  #case-sorting li a {
    color: #000000;
    font-size: 1.125rem; }
  #case-sorting:before {
    content: '';
    display: block;
    width: 58px;
    height: 100%;
    position: absolute;
    right: 0;
    background-color: #ffffff;
    transition: all .25s ease-in-out;
    z-index: 1; }
  #case-sorting #sorting-info {
    z-index: 2; }
  #case-sorting ul {
    position: relative;
    right: -500px;
    transition: all .25s ease-in-out;
    margin: 0;
    margin-top: 55px;
    padding: 0px 90px 60px; }
    #case-sorting ul li {
      list-style: none;
      margin-bottom: 15px; }
      #case-sorting ul li a {
        position: relative; }
        #case-sorting ul li a span {
          position: relative;
          z-index: 2; }
        #case-sorting ul li a:after {
          content: '';
          display: inline-block;
          width: 0%;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          background-color: #62FF00;
          transition: all .25s ease-in-out;
          z-index: 1; }
      #case-sorting ul li:hover a,
      #case-sorting ul li.is-active a {
        color: #000000;
        padding: 0 5px; }
        #case-sorting ul li:hover a:after,
        #case-sorting ul li.is-active a:after {
          width: 100%; }
      #case-sorting ul li.is-active a {
        border-bottom: 2px solid #000000; }
  #case-sorting.open {
    z-index: 999; }
    #case-sorting.open:before {
      width: 100%; }
    #case-sorting.open ul {
      right: 0; }
