@import 'settings';
@import 'foundation';
@import 'df-template-settings';


#case-list-container:only-of-type{
    margin-bottom: $ef-global-padding/2;
    margin-top: 120px;
    @include breakpoint('large'){
        margin-top: 150px; //$ef-global-padding;
        margin-bottom: $ef-global-padding;
    }
}

#case-list{
    position: relative;
    z-index: 2;
    display: grid;
    
    grid-template-columns:repeat(2, minmax(0, 1fr));
    gap: $ef-global-padding/2; //0.125rem;

    @include breakpoint('large'){
        grid-template-columns:repeat(3, minmax(0, 1fr));
        gap: $ef-global-padding; //0.125rem;
        //top: -70px;
    }

    @include breakpoint('xxlarge'){
        grid-template-columns:repeat(4, minmax(0, 1fr));
    }

    // padding: $ef-global-padding/2;
    // @include breakpoint(large){
    //     padding: $ef-global-padding;
    // }

    @include breakpoint(large down){
        padding: 0 15px;
    }

    &>div{
        position: relative;
        display: inline-flex;
        align-items: flex-end;

        &>a{
            display: block;
            width: 100%;
        }
    }

    // picture{
    //     aspect-ratio: 3 / 4;
    // }
    
    picture, video{
        position: relative;
        // img{
        //     position: absolute;
        //     bottom: 0;
        // }
	}

    picture{
        margin-bottom: calc(0.5rem + 7px);
        img{
            width: 100%;
        }
    }

    video{
        margin-bottom: 0.5rem;
    }
    //picture, video{
	// 	display: inline-block;
	// 	margin-bottom: 0.5rem;
	// 	width: 100%;
	// 	// max-height: 500px;
	// 	aspect-ratio: 3 / 4;
	// 	object-fit: cover;
	// 	overflow: hidden;
	// }

	.span-2{
		picture, video{
			aspect-ratio: 4 / 3;
		}
	}

    picture img,
	video {
        transition: all .25s ease-in-out;
        //filter:grayscale(85%);
        &:hover{
            // animation: mymove .5s;
                //infinite
            //filter:grayscale(0%);
        }
    }

	.case-data{

        color: $black;

        min-height: 130px;
	
        span {
            display: block;
        }

        font-size: rem-calc(11px);
        
        .listing-desc, .listing-link{
            color: $dark-gray;
        }

        @include breakpoint(large){
            .listing-title{
                font-size: rem-calc(18px);
            }
            .listing-desc, .listing-link{
                font-size: rem-calc(14px);
            }
        }

        .listing-link{
            display: inline-block;
            position: relative;
            
            &:after{
                transition: all .25s ease-in-out;
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: $dark-gray;
                bottom: 4px;
            }
        }

		transition: all .25s ease-in-out;

		// background-image: linear-gradient(currentColor, currentColor);
		// background-position: 0% 100%;
		// background-repeat: no-repeat;
		// background-size: 0% 2px;
    }

    .categories{
        font-size: rem-calc(10px);
		
        @include breakpoint(large){
			font-size: rem-calc(14px);
		}
        
        &, [data-filter]{
            color: $dark-gray;
        }

        [data-filter]{
            display: inline-block;
            &:hover{
                cursor: pointer;
                color: $dark-gray;
            }
        }
    }

    a:hover{ 
        picture img,
		video{
            will-change: transform;
            transform: scale(1.01);
        }

		.case-data,
        & + .case-data{
			// text-decoration: underline !important;
			padding-left: 5px;
			// background-size: 100% 2px;
		}

        .listing-link{
            color: $black;
            &:after{
                background-color: $black;
            }
        }

    }

    .case{
        &.no-media
        {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            @include breakpoint(medium down){
                grid-column: span 2;
            }
        }
        
        &.span-2{
            grid-column: span 2;
        }

    }
}


#case-list .case.hide{
    display: none;
}


#sorting-info {
    display: none;
    @include breakpoint(xlarge){
        display: flex;
    }
    flex-direction: column;
    position: absolute;
    top: 20px;
    right: 14px;
    cursor: pointer;

    &:before{
        content: '';
        display: inline-block;
        width: 25px;
        height: 25px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.029 29.862'%3E%3Cg data-name='Group 9743' fill='%2362ff00'%3E%3Cpath data-name='Path 9470' d='M.109 2.483 0 6.043l10.645-.009h8.74l10.536-.006.109-3.56Z'/%3E%3Cpath data-name='Path 9471' d='M.109 13.178 0 16.739l10.645-.01h8.74l10.536-.006.109-3.56Z'/%3E%3Cpath data-name='Path 9472' d='M.109 23.834 0 27.395l10.645-.011h19.276l.109-3.56Z'/%3E%3Cpath data-name='Rectangle 2509' d='M5.566 0h8.509v8.51H5.566z'/%3E%3Cpath data-name='Rectangle 2510' d='M18.329 10.696h8.509v8.509h-8.509z'/%3E%3Cpath data-name='Rectangle 2511' d='M9.82 21.353h8.509v8.509H9.82z'/%3E%3C/g%3E%3C/svg%3E");
        //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.908 23.908'%3E%3Cpath data-name='Path 9456' d='M18.232 23.908 0 5.676 5.676 0l18.232 18.189Zm-12.516-.172L.172 18.103 18.016.258l5.59 5.547Z' fill='%2362FF00'/%3E%3C/svg%3E");
        margin-bottom: 20px;
    }

    svg{
        transition: all .25s ease-in-out 500ms;
    }

    svg#sort-text{
       // height: 29px;
       // width: 328px;
        height: 328px;
        width: 29px;
    }

    svg#sort-text text{
        display: none;
        fill:$black;
        font-size:rem-calc(18px);
        font-family: $body-font-color;
        font-weight: 600;

        @include breakpoint(medium){
            display: block;
        }
    
    }
}

#case-sorting.open{
    //min-width: 450px;
    svg{
        transition: all .25s ease-in-out;
        opacity: 0;
    }
    // ul{
    //     // display: block;
    //     right: initial;
    //     opacity: 1;
    // }

    // p:before{
    #sorting-info:before{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.908 23.908'%3E%3Cpath data-name='Path 9456' d='M18.232 23.908 0 5.676 5.676 0l18.232 18.189Zm-12.516-.172L.172 18.103 18.016.258l5.59 5.547Z' fill='%2362FF00'/%3E%3C/svg%3E");
        //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.029 29.862'%3E%3Cg data-name='Group 9743' fill='%2362ff00'%3E%3Cpath data-name='Path 9470' d='M.109 2.483 0 6.043l10.645-.009h8.74l10.536-.006.109-3.56Z'/%3E%3Cpath data-name='Path 9471' d='M.109 13.178 0 16.739l10.645-.01h8.74l10.536-.006.109-3.56Z'/%3E%3Cpath data-name='Path 9472' d='M.109 23.834 0 27.395l10.645-.011h19.276l.109-3.56Z'/%3E%3Cpath data-name='Rectangle 2509' d='M5.566 0h8.509v8.51H5.566z'/%3E%3Cpath data-name='Rectangle 2510' d='M18.329 10.696h8.509v8.509h-8.509z'/%3E%3Cpath data-name='Rectangle 2511' d='M9.82 21.353h8.509v8.509H9.82z'/%3E%3C/g%3E%3C/svg%3E");
        //transform: rotate(90deg);
    }
}


.case .paragraph-container a{
    font-size: rem-calc(18px);
    // border-bottom: 4px solid $black;
    position: relative;
    color: $dark-gray;

    &:after{
        transition: all .25s ease-in-out;
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: $dark-gray;
        bottom: 0px;
    }
    &:hover{
        color: $black;
        &:after{
            background-color: $black;
        }
    }
}


#case-sorting
{
    color: $black;
    z-index: 1;
    position: fixed;
    //width: 0;
    top: 290px;
    right: 0;
    transition: all .25s ease-in-out;
    display: none;
    bottom: 0;
    @include breakpoint(large){
        display: block;
        bottom: auto;
    }

    p, li{
        &,
        a{
            color: $black;
            font-size: rem-calc(18px);
        }
    }

    &:before{
        content: '';
        display: block;
        width: 58px;
        height: 100%;
        position: absolute;
        right: 0;
        background-color: $white;
        transition: all .25s ease-in-out;
        z-index: 1;
    }

    #sorting-info{
        z-index: 2;
    }

    ul{
        position: relative;
        right: -500px;
        transition: all .25s ease-in-out;

        margin: 0;
        margin-top: 55px; // size of X
        padding: 0px 90px 60px;


        li{
            list-style: none;
            margin-bottom: 15px;
            a{
                position: relative;
                span{
                    position: relative;
                    z-index: 2;
                }
                &:after{
                    content: '';
                    display: inline-block;
                    width: 0%;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: #62FF00;
                    transition: all .25s ease-in-out;
                    z-index: 1;
                }
            }

            &:hover a,
            &.is-active a{
                color: $black;
                padding: 0 5px;
                &:after{
                    width: 100%;
                }
            }
            &.is-active a{
                border-bottom: 2px solid $black;
            }
        }
    }

    &.open{
        z-index: 999;
        &:before{
            width: 100%;
        }
        ul{
            right: 0;
        }
    }
}


  
// .scrolled-first-section #case-sorting
// {
//     background-color: #f5f5f5;
    
//     top: calc(var(--header-height) + 20px);
// }